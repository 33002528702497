@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:wdth,wght@125,900&display=swap");

body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

h1 {
    font-size: 4vw;

    &.title {
        padding: 0rem 7rem;
    }
}
h2 {
    font-size: 3vw;
}
h3 {
    font-size: 2.5vw;
}
h4 {
    font-size: 2vw;
}
h5 {
    font-size: 1.5vw;
}

p {
    font-size: 14px;

    &.description {
        font-size: 1vw;
    }

    &.title-description {
        font-size: 1.2vw;
        padding: 0rem 7rem;
    }

    &.description-card {
        font-size: 15px;
    }
}

.navbar {
    background-color: #fefefe;
    padding: 1.5rem 1rem;

    & .nav-link {
        padding: 0.3rem 2.5rem !important;

        font-size: 1rem;
        font-style: italic;
        font-weight: 700;
        color: #95a5a6;
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;
        border-radius: 10rem;
        margin: 0.5rem 3rem;
        border: 0px;

        &.nav-products {
            margin: 0.5rem;
            padding: 0.3rem 1rem !important;
            font-size: 14px;
        }

        &:hover,
        &.nav-active {
            background-color: #0f3057;
            color: #fefefe;
        }
    }

    & .navbar-brand {
        padding: 0.5rem 2.5rem !important;

        img {
            width: 13rem;
        }
    }
}

.section-height-100 {
    min-height: 95vh;
    display: flex;

    &.center-items {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.section-height-110 {
    min-height: 100vh;
    display: flex;

    &.center-items {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.bg-home {
        background-image: url("../imgs/home/banner-home-1.jpg");
    }
    &.bg-home-2 {
        background-image: url("../imgs/home/banner-home-2.jpg");
    }
    &.bg-home-3 {
        background-image: url("../imgs/home/banner-home-3.jpg");
    }
    &.bg-home-4 {
        background-image: url("../imgs/home/banner-home-4.jpg");
    }

    &.bg-about-1 {
        background-image: url("../imgs/banners-about/banner-about-1.jpg");
    }
    &.bg-about-2 {
        background-image: url("../imgs/banners-about/banner-about-2.jpg");
    }
    &.bg-about-3 {
        background-image: url("../imgs/banners-about/banner-about-3.jpg");
    }

    &.bg-contact-1 {
        background-image: url("../imgs/banners-contact/banner-contact-1.jpg");
    }

    &.bg-compliance {
        background-image: url("../imgs/banners-products/banner-compliance.jpg");
    }
    &.bg-incident {
        background-image: url("../imgs/banners-products/banner-incident.jpg");
    }
    &.bg-waf {
        background-image: url("../imgs/banners-products/banner-waf.jpg");
    }
    &.bg-ssl {
        background-image: url("../imgs/banners-products/banner-ssl.jpg");
    }
    &.bg-pentest {
        background-image: url("../imgs/banners-products/banner-pentest.jpg");
    }
}

.bg-dark-trust {
    background-color: #0f3057;
    color: #fefefe;
}
.bg-dark-success-trust {
    background: transparent linear-gradient(125deg, #05111d 0%, #0f3057 100%) 0%
        0% no-repeat padding-box;
    color: #fefefe;
}
.bg-dark-danger-trust {
    background: transparent linear-gradient(125deg, #05111d 0%, #e32828 100%) 0%
        0% no-repeat padding-box;
    color: #fefefe;
}
.bg-white-trust {
    background-color: #8eb7e9;
}
.color-white {
    color: #fefefe;
}
.color-gray {
    color: #707c7c;
}
.color-blue {
    color: #0f3057;
}

.padding-container {
    padding-top: 8rem;
}
.padding-container-y {
    padding: 6rem 0rem;
}

// Buttons
.btn {
    border-radius: 5px;
    border: 0px;
    height: 40px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 2.4px;
}

.btn-primary {
    padding: 1rem 2rem;
    display: inline-flex;
    border-radius: 3rem;
    font-style: italic;
    font-weight: 700;
    text-decoration: none;
    transition: all 0.3s ease;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #0f3057;
    color: #0f3057;

    &.btn-white-border {
        background-color: transparent;
        border: 1px solid #fefefe;
        color: #fefefe;

        &:hover {
            background-color: #fefefe;
            color: #0f3057;
        }
    }

    &.btn-blue-border {
        background-color: transparent;
        border: 1px solid #0f3057;
        color: #0f3057;

        &:hover {
            background-color: #0f3057;
            color: #fefefe;
        }
    }

    &.btn-footer {
        background-color: transparent;

        &:hover {
            background-color: #0f3057;
            color: #fff;
        }
    }

    &.back-to-home {
        color: #fefefe;
        background-color: #0f3057;

        &:hover {
            background-color: #fefefe;
            color: #0f3057;
        }
    }

    &:disabled {
        background-color: #dedede;
        color: #0f3057;
        transition: all 0.7s ease;
    }

    & i {
        font-size: 16px;
    }
}

.btn-blue-dark {
    color: #fff;
    background: transparent linear-gradient(126deg, $secondary 0%, #0f3057 100%)
        0% 0% no-repeat padding-box;

    &:disabled {
        background: transparent linear-gradient(128deg, $black 0%, #dedede 100%)
            0% 0% no-repeat padding-box;
    }

    &:hover {
        background: transparent
            linear-gradient(126deg, $secondary 0%, #0f3057 100%);
        border-color: #fefefe;
    }
}

// Home
.card {
    &.card-solucoes {
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 1rem;
        min-height: 25rem;
        height: 100%;
        justify-content: space-between;

        & .card-solucoes-header {
            min-height: 8rem;
            border-radius: 1rem 1rem 0 0;
            border: 2px solid #fff;

            &.header-1 {
                background: url("../imgs/cards-home/credit_card.png") center
                    center no-repeat;
                background-size: cover;
            }

            &.header-2 {
                background: url("../imgs/cards-home/lock_safe.png") center
                    center no-repeat;
                background-size: cover;
            }

            &.header-3 {
                background: url("../imgs/cards-home/web_browser.png") center
                    center no-repeat;
                background-size: cover;
            }

            &.header-4 {
                background: url("../imgs/cards-home/notebook.png") center center
                    no-repeat;
                background-size: cover;
            }

            &.header-5 {
                background: url("../imgs/cards-home/planning.png") center center
                    no-repeat;
                background-size: cover;
            }
        }

        & .card-produtos-header {
            min-height: 5rem;
            border-radius: 1rem 1rem 0 0;
            border: 2px solid #fff;

            &.header-gray {
                background-color: #95a5a6;
                background-size: cover;
            }
        }
    }

    &.card-contact {
        background: transparent
            linear-gradient(126deg, #0f3057 5%, #8eb7e9 120%) 0% 0% no-repeat
            padding-box;
        padding: 2rem 4rem;
    }
}

.table-description {
    padding: 0rem 2rem;

    &.fs-16 {
        table {
            td {
                font-size: 16px;
            }
        }
    }

    table {
        text-align: center;

        th {
            color: #0f3057;
            font-size: 1rem;
        }
        td {
            color: #0f3057;
            font-size: 1.5rem;
            font-weight: 300;

            svg.fa-check {
                color: #28a745;
            }
            svg.fa-xmark {
                color: #dc3545;
            }
        }
    }
}

.slider-description {
    font-size: 3rem;
    color: #95a5a6;

    p {
        font-size: 16px;
    }
}

.solutions-slider {
    .slick-next:before,
    .slick-prev:before {
        color: #fefefe !important;
    }

    .slick-dots {
        bottom: -27px !important;
    }

    ul.slick-dots li button:before {
        color: #fefefe !important;
    }
}

.slick-next:before,
.slick-prev:before {
    color: #0f3057 !important;
}
.slick-dots {
    bottom: 10px !important;
}

.icon-seal {
    background-color: #0f3057;
    border-radius: 50%;
    min-height: 60px;
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fefefe;
    font-size: 36px;
}

// Forms
form {
    .form-group {
        & .form-label {
            font-size: 14px;
        }
        & .form-control {
            color: #fefefe !important;
            font-size: 16px;
            padding: 0.1rem;
            background: transparent;
            border: none;
            border-bottom: 2px solid #fefefe;
            border-radius: 0px;
            transition: box-shadow 0.5s ease, border 0.1s linear;

            &:focus,
            &:active {
                box-shadow: 0 13px 30px -18px rgba(170, 170, 170, 1);
                border-bottom: 4px solid #fefefe;
            }
        }
    }
}
// Footer
.card-lgpd {
    bottom: 5px;
    display: flex;
    justify-content: center;

    & .card {
        padding: 20px;
        width: 70%;
        box-shadow: rgb(170 170 170) 0px 0px 10px;
        border: 0px;

        & button {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
}

.loading {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    z-index: 999;
    background: rgba(0, 0, 0, 0.8);

    &:before,
    &:after,
    div {
        width: 400px;
        height: 400px;
        top: 50%;
        left: 50%;
        display: block;
        position: absolute;
        border-radius: 50%;
        border: 6px solid $primary;
    }

    &:before {
        content: "";
        animation: scale 1s ease-in infinite;
    }

    &:after {
        content: "";
        animation: scale 2s ease-in infinite;
    }

    div {
        animation: scale 3s ease-in infinite;
    }
}

.loading.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
}

@keyframes scale {
    from {
        transform: translate(-50%, -50%) scale(0, 0);
    }

    to {
        transform: translate(-50%, -50%) scale(1, 1);
    }
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 12vw;

        &.title {
            padding: 0rem;
        }
    }
    h2 {
        font-size: 9vw;
    }
    h3 {
        font-size: 8vw;
    }
    h4 {
        font-size: 7vw;
    }
    h5 {
        font-size: 6vw;
    }

    p {
        font-size: 14px;

        &.description {
            font-size: 5vw;
        }

        &.title-description {
            font-size: 5vw;
            padding: 0rem;
        }

        &.description-card {
            font-size: 15px;
        }
    }

    button.back-to-home {
        width: 100%;
    }

    .section-height-110 {
        height: auto !important;

        & .container {
            padding: 4rem 2rem;
        }
    }

    .card {
        &.card-contact {
            padding: 2rem;
        }
    }

    .border-end {
        border: none !important;
    }
}

@media screen and (min-width: 1200px) {
    .col-custom {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media screen and (max-width: 1400px) {
    .navbar .nav-link {
        padding: 0.3rem 2rem !important;
        font-size: 1rem;
    }
}
